import { lazy } from "react";
import LINKS from "utils/constants/links";
import lazyRetry from "utils/helpers/lazyRetry";
import { FEATURE_FLAGS } from "../feature-flags";
import { ReactComponent as AdminIcon } from "icons/admin_management.svg";

const AddProjectData = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'AddProjectDataForm' */ "pages/ProjectData/AddProjectDataForm"
    )
  )
);

const TimesheetList = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'TimesheetList' */ "pages/Timesheet/TimesheetList"
    )
  )
);

const ProjectData = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'ProjectData' */ "pages/ProjectData/ProjectDataListing"
    )
  )
);

const EditProjectData = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'EditProjectData' */ "pages/ProjectData/EditProjectDataForm"
    )
  )
);

const ClientOrganizationConfiguration = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'ClientOrganizationConfiguration' */ "pages/ProjectData/ClientConfiguration/ClientOrganization"
    )
  )
);

const BudgetTracking = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'BudgetTracking' */ "pages/ProjectData/BudgetTracking"
    )
  )
);

const BudgetAlerts = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'BudgetAlerts' */ "pages/ProjectData/BudgetAlerts"
    )
  )
);

const PurchaseOrders = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'PurchaseOrders' */ "pages/ProjectData/ProjectDataPurchaseOrderListing"
    )
  )
);

const Proposals = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'Proposals' */ "pages/Proposals/ProposalListing"
    )
  )
);

const TimeEntryReports = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'TimeEntryReports' */ "pages/Timesheet/TimeEntryReports"
    )
  )
);

const AdministrationSetting = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'AdministrationSetting' */ "pages/AdministrationSetting"
    )
  )
);

const TimeEntry = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: 'TimeEntry' */ "pages/Timesheet/TimeEntry")
  )
);

const InvoiceList = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'InvoiceList' */ "pages/InvoiceForm/InvoiceList"
    )
  )
);

const InvoiceReport = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'InvoiceReport' */ "pages/InvoiceForm/InvoiceReports"
    )
  )
);

const InvoiceApprovals = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: 'InvoiceApprovals' */ "pages/InvoiceApprovals")
  )
);

const AddInvoice = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: 'AddInvoice' */ "pages/InvoiceForm/AddInvoice")
  )
);

const EditInvoice = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'EditInvoice' */ "pages/InvoiceForm/EditInvoice"
    )
  )
);

const ExpenseReportListing = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'ExpenseReportListing' */ "pages/ExpenseReport/ExpenseReportListing"
    )
  )
);

const ReportOfExpenses = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'ExpenseReportListing' */ "pages/ExpenseReport/ReportOfExpenses"
    )
  )
);

const EditExpenseReport = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'EditExpenseReport' */ "pages/ExpenseReport/EditExpenseReport"
    )
  )
);

const administrationRoutes = Object.freeze({
  id: LINKS.ADMINISTRATION.TITLE,
  path: LINKS.ADMINISTRATION.HREF,
  icon: <AdminIcon />,
  component: null,
  roles: LINKS.ADMINISTRATION.ROLES,
  children: [
    {
      path: LINKS.PROJECT_DATA.HREF,
      name: LINKS.PROJECT_DATA.TITLE,
      component: ProjectData,
      feature: FEATURE_FLAGS.ProjectData,
      roles: LINKS.PROJECT_DATA.ROLES,
    },
    {
      path: LINKS.CLIENTS_ORGANIZATION.HREF,
      name: LINKS.CLIENTS_ORGANIZATION.TITLE,
      component: ClientOrganizationConfiguration,
      feature: FEATURE_FLAGS.Clients,
      roles: LINKS.CLIENTS_ORGANIZATION.ROLES,
    },
    {
      path: LINKS.BUDGET_TRACKING.HREF,
      name: LINKS.BUDGET_TRACKING.TITLE,
      component: BudgetTracking,
      feature: FEATURE_FLAGS.BudgetTracker,
      roles: LINKS.BUDGET_TRACKING.ROLES,
    },
    {
      path: LINKS.BUDGET_ALERTS.HREF,
      name: LINKS.BUDGET_ALERTS.TITLE,
      component: BudgetAlerts,
      feature: FEATURE_FLAGS.BudgetAlerts,
      roles: LINKS.BUDGET_ALERTS.ROLES,
    },
    {
      path: LINKS.ADD_PROJECT_DATA.HREF,
      name: LINKS.ADD_PROJECT_DATA.TITLE,
      component: AddProjectData,
      feature: FEATURE_FLAGS.ProjectData,
      roles: LINKS.ADD_PROJECT_DATA.ROLES,
      isNotSlide: true,
    },
    {
      path: LINKS.EDIT_PROJECT_DATA.HREF,
      name: LINKS.EDIT_PROJECT_DATA.TITLE,
      component: EditProjectData,
      feature: FEATURE_FLAGS.ProjectData,
      isNotSlide: true,
    },
    // {
    //   path: LINKS.EDIT_ORGANIZATION_CLIENTS.HREF,
    //   name: LINKS.EDIT_ORGANIZATION_CLIENTS.TITLE,
    //   component: ClientOrganizationConfiguration,
    //   // isNotSlide: true,
    // },

    {
      path: LINKS.PURCHASE_ORDERS.HREF,
      name: LINKS.PURCHASE_ORDERS.TITLE,
      component: PurchaseOrders,
      feature: FEATURE_FLAGS.ProjectControlPurchaseOrder,
      roles: LINKS.PURCHASE_ORDERS.ROLES,
    },
    {
      path: LINKS.PROPOSALS.HREF,
      name: LINKS.PROPOSALS.TITLE,
      component: Proposals,
      roles: LINKS.PROPOSALS.ROLES,
      feature: FEATURE_FLAGS.Proposals,
    },
    {
      path: LINKS.TIME_ENTRY_REPORTS.HREF,
      name: LINKS.TIME_ENTRY_REPORTS.TITLE,
      component: TimeEntryReports,
      feature: FEATURE_FLAGS.TimesheetReports,
      roles: LINKS.TIME_ENTRY_REPORTS.ROLES,
    },
    {
      path: LINKS.TIMESHEET_PROJECT_CONTROL.HREF,
      name: LINKS.TIMESHEET_PROJECT_CONTROL.TITLE,
      component: TimesheetList,
      feature: FEATURE_FLAGS.TimesheetApprovals,
      roles: LINKS.TIMESHEET_PROJECT_CONTROL.ROLES,
    },
    {
      path: LINKS.ADD_TIMESHEET_ENTRY_PROJECT_CONTROL.HREF,
      name: LINKS.ADD_TIMESHEET_ENTRY_PROJECT_CONTROL.TITLE,
      feature: FEATURE_FLAGS.TimesheetApprovals,
      component: TimeEntry,
      isNotSlide: true,
    },
    {
      path: LINKS.APPROVED_TIME_ENTRY.HREF,
      name: LINKS.APPROVED_TIME_ENTRY.TITLE,
      component: TimeEntry,
      feature: FEATURE_FLAGS.ApprovedTimeentry,
      isNotSlide: true,
    },
    {
      path: LINKS.ADD_INVOICE.HREF,
      name: LINKS.ADD_INVOICE.TITLE,
      component: AddInvoice,
      feature: FEATURE_FLAGS.ProjectControlInvoice,
      isNotSlide: true,
    },
    {
      path: LINKS.EDIT_INVOICE.HREF,
      name: LINKS.EDIT_INVOICE.TITLE,
      component: EditInvoice,
      feature: FEATURE_FLAGS.ProjectControlInvoice,
      isNotSlide: true,
    },
    {
      path: LINKS.APPROVED_TIMESHEET.HREF,
      name: LINKS.APPROVED_TIMESHEET.TITLE,
      component: TimesheetList,
      feature: FEATURE_FLAGS.ApprovedTimeentry,
      roles: LINKS.APPROVED_TIMESHEET.ROLES,
    },
    {
      path: LINKS.INVOICES.HREF,
      name: LINKS.INVOICES.TITLE,
      component: InvoiceList,
      feature: FEATURE_FLAGS.ProjectControlInvoice,
      roles: LINKS.INVOICES.ROLES,
    },
    {
      path: LINKS.INVOICES_REPORTS.HREF,
      name: LINKS.INVOICES_REPORTS.TITLE,
      component: InvoiceReport,
      feature: FEATURE_FLAGS.InvoiceReports,
      roles: LINKS.INVOICES_REPORTS.ROLES,
    },
    {
      path: LINKS.INVOICES_APPROVALS.HREF,
      name: LINKS.INVOICES_APPROVALS.TITLE,
      component: InvoiceApprovals,
      feature: FEATURE_FLAGS.InvoiceApprovals,
      roles: LINKS.INVOICES_APPROVALS.ROLES,
    },
    {
      path: LINKS.EXPENSE_REPORT_APPROVAL_LIST.HREF,
      name: LINKS.EXPENSE_REPORT_APPROVAL_LIST.TITLE,
      component: ExpenseReportListing,
      feature: FEATURE_FLAGS.ExpenseReportApproval,
      roles: LINKS.EXPENSE_REPORT_APPROVAL_LIST.ROLES,
    },
    {
      path: LINKS.EDIT_EXPENSE_REPORT_APPROVAL.HREF,
      name: LINKS.EDIT_EXPENSE_REPORT_APPROVAL.TITLE,
      component: EditExpenseReport,
      feature: FEATURE_FLAGS.ExpenseReportApproval,
      roles: LINKS.EDIT_EXPENSE_REPORT_APPROVAL.ROLES,
      isNotSlide: true,
    },
    {
      path: LINKS.REPORT_OF_EXPENSE.HREF,
      name: LINKS.REPORT_OF_EXPENSE.TITLE,
      component: ReportOfExpenses,
      feature: FEATURE_FLAGS.ReportOfExpenses,
      roles: LINKS.REPORT_OF_EXPENSE.ROLES,
    },
    {
      path: LINKS.ADMINISTRATION_SETTING.HREF,
      name: LINKS.ADMINISTRATION_SETTING.TITLE,
      component: AdministrationSetting,
      roles: LINKS.ADMINISTRATION_SETTING.ROLES,
    },
  ],
});

export default administrationRoutes;

import { lazy } from "react";
import { ReactComponent as TimesheetIcon } from "icons/timesheet.svg";
import LINKS from "utils/constants/links";
import lazyRetry from "utils/helpers/lazyRetry";
import { FEATURE_FLAGS } from "../feature-flags";

// const SignIn = lazy(() =>
//   lazyRetry(() => import(/* webpackChunkName: 'SignIn' */ "pages/auth/SignIn"))
// );

const TimesheetList = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: 'SignIn' */ "pages/Timesheet/TimesheetList")
  )
);

const TimeEntry = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: 'SignIn' */ "pages/Timesheet/TimeEntry")
  )
);

// const TimeEntry = lazy(() =>
//   lazyRetry(() =>
//     import(
//       /* webpackChunkName: 'SignIn' */ "pages/Timesheet/TimeEntry/newIndex"
//     )
//   )
// );

const SettingPage = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: 'SignIn' */ "pages/Timesheet/SettingPage")
  )
);

const ExpenseReportListing = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'NewExpenseReportListing' */ "pages/ExpenseReport/ExpenseReportListing"
    )
  )
);

const AddExpenseReport = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'NewAddExpenseReport' */ "pages/ExpenseReport/AddExpenseReport"
    )
  )
);

const EditExpenseReport = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'NewEditExpenseReport' */ "pages/ExpenseReport/EditExpenseReport"
    )
  )
);

const timesheetRoutes = Object.freeze({
  id: LINKS.TIME_MANAGEMENT.TITLE,
  path: LINKS.TIME_MANAGEMENT.HREF,
  // pathId: "timesheet",
  icon: <TimesheetIcon />,
  component: null,
  roles: LINKS.TIME_MANAGEMENT.ROLES,
  children: [
    {
      path: LINKS.TIMESHEET.HREF,
      name: LINKS.TIMESHEET.TITLE,
      roles: LINKS.TIMESHEET.ROLES,
      component: TimesheetList,
    },
    {
      path: LINKS.ADD_TIMESHEET_ENTRY.HREF,
      name: LINKS.ADD_TIMESHEET_ENTRY.TITLE,
      component: TimeEntry,
      isNotSlide: true,
    },
    {
      path: LINKS.TIMESHEET_SETTING.HREF,
      name: LINKS.TIMESHEET_SETTING.TITLE,
      component: SettingPage,
      isNotSlide: true,
    },
    {
      path: LINKS.EMPLOYEE_EXPENSE_REPORT_LIST.HREF,
      name: LINKS.EMPLOYEE_EXPENSE_REPORT_LIST.TITLE,
      component: ExpenseReportListing,
      feature: FEATURE_FLAGS.Expenses,
      roles: LINKS.EMPLOYEE_EXPENSE_REPORT_LIST.ROLES,
    },
    {
      path: LINKS.ADD_EMPLOYEE_EXPENSE_REPORT.HREF,
      name: LINKS.ADD_EMPLOYEE_EXPENSE_REPORT.TITLE,
      component: AddExpenseReport,
      feature: FEATURE_FLAGS.Expenses,
      roles: LINKS.ADD_EMPLOYEE_EXPENSE_REPORT.ROLES,
      isNotSlide: true,
    },
    {
      path: LINKS.EDIT_EMPLOYEE_EXPENSE_REPORT.HREF,
      name: LINKS.EDIT_EMPLOYEE_EXPENSE_REPORT.TITLE,
      component: EditExpenseReport,
      feature: FEATURE_FLAGS.Expenses,
      roles: LINKS.EDIT_EMPLOYEE_EXPENSE_REPORT.ROLES,
      isNotSlide: true,
    },
  ],
});

export default timesheetRoutes;

import { getJQ, post, get } from "services/axios";

const sendErrorEmail = async (payload) =>
  await post(`/v2/general/sendEmail`, payload);

const signUrl = async (payload) => await post(`/v2/general/signUrl`, payload);

const uploadMultipleFiles = async (payload) =>
  await post(`/v2/general/uploadMultipleFile`, payload);

const uploadSingleFile = async (payload) =>
  await post(`/v2/general/uploadFile`, payload);

const getSystemsAndDeliverables = async (payload) =>
  await getJQ(`/v2/general/filters`, payload);

const getAllCategories = async () =>
  await get(`/v2/general/filters/categories`);

export {
  sendErrorEmail,
  signUrl,
  uploadMultipleFiles,
  uploadSingleFile,
  getSystemsAndDeliverables,
  getAllCategories,
};

import apiAxios, { getJQ, put, del, post, get } from "services/axios";

const getProjects = async (data) => await getJQ("projects", data);

const getProjectDetails = async (projectId) =>
  await get(`/v2/projects/${projectId}`);

const getProjectDetailsDocument = async (projectId) =>
  await get(`/v2/projects/${projectId}/projectDocument`);
const createProject = async (params) => {
  return await post("projects", params);
};

const getInsights = async (projectId) => {
  return await get(`/v2/projects/${projectId}/insights`);
};

const getPhases = async (projectId) => {
  return await get(`/v2/projects/${projectId}/phases`);
};
const getPhaseDetail = async (projectId, phaseId) => {
  return await get(`/v2/projects/${projectId}/phaseDetails/${phaseId}`);
};

const getMultiPhaseDetail = async (projectId, multiPhaseIdsObj) => {
  return await getJQ(
    `/v2/projects/${projectId}/phaseDetails/`,
    multiPhaseIdsObj
  );
};

const updateProject = async (params) => {
  return await put("projects", params);
};

const deleteProject = async (params) => {
  return await del("projects", params);
};

const createProjectPhase = async (params) => {
  return await post(["projects", "nested"], params);
};

const updateProjectPhase = async (params) => {
  return await put(["projects", "nested"], params);
};

const updatePhaseOrder = async (
  projectId,
  params,
  updateSystemInstance = false
) => {
  return await put(`v2/projects/${projectId}/updatePhaseOrder`, {
    phases: params,
    updateSystemInstance,
  });
};

const updateProjectOrder = async (params) => {
  return await put(`v2/projects/updateProjectOrder`, {
    projects: params,
  });
};

const deleteProjectPhase = async (params) => {
  return await del(["projects", "nested"], params);
};

const exportProjectSystems = async (projectId, params = {}) => {
  return apiAxios.get(`/v2/projects/${projectId}/systems/export`, {
    responseType: "blob",
    params,
  });
};

const createCustomNotification = async (projectId, params) => {
  return await post(`/v2/projects/${projectId}/customNotifications`, params);
};

const getCustomNotifications = async (projectId, skip, limit) =>
  await getJQ(`/v2/projects/${projectId}/customNotifications`, { skip, limit });

const deleteCustomNotification = async (projectId, id) =>
  await del(`/v2/projects/${projectId}/customNotifications/${id}`);

const exportProjectSnapShot = async (projectId, params = {}) => {
  return apiAxios.get(`/v2/projects/${projectId}/snapshot/export`, {
    responseType: "blob",
    params,
  });
};

const exportProjectDepartmentSnapShot = async (projectId, params = {}) => {
  return apiAxios.get(`/v2/projects/${projectId}/departmentSnapshot/export`, {
    responseType: "blob",
    params,
  });
};

const exportProjectMulitPhaseMatrix = async (projectId, params = {}) => {
  return apiAxios.get(`/v2/projects/${projectId}/mulitphasesnapshot/export`, {
    responseType: "blob",
    params,
  });
};

const exportPhaseSnapshot = async (projectId, params = {}) => {
  return apiAxios.get(`/v2/projects/${projectId}/phaseSnapshot/export`, {
    responseType: "blob",
    params,
  });
};

const exportProjectSystemSnapShot = async (projectId, params = {}) => {
  return apiAxios.get(`/v2/projects/${projectId}/system-snapshot/export`, {
    responseType: "blob",
    params,
  });
};

const shareSnapshot = async (projectId, params) => {
  return await apiAxios.post(
    `/v2/projects/${projectId}/shareSnapshot`,
    params,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

const shareMatrixSnapshot = async (projectId, params) => {
  return await apiAxios.post(
    `/v2/projects/${projectId}/shareMatrixSnapshot`,
    params,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

const addSnapshotFilters = async (projectId, params) => {
  return await post(`/v2/projects/${projectId}/addSnapshotFilter`, params);
};

const addPhaseSnapshotFilter = async (projectId, params) => {
  return await post(`/v2/projects/${projectId}/addPhaseSnapshotFilter`, params);
};

const getProjectPreferences = async (projectId) =>
  await get(`/v2/projects/${projectId}/modulePreference`);
const updateProjectPreferences = async (projectId, params) => {
  return await put(`/v2/projects/${projectId}/modulePreferences`, params);
};

const getDocumentSnapshot = async (projectId) =>
  await getJQ(`v2/projects/${projectId}/documentSnapshot/`, {
    // sortBy: {
    //   reviewDueDate: "ASC",
    // }
  });

const exportDocumentSnapshot = async (projectId, params = {}) =>
  apiAxios.get(`/v2/projects/${projectId}/documentSnapshot/export`, {
    responseType: "blob",
    params,
  });

const getCheckAssignedProject = async () =>
  await get(`/v2/projects/check/assignedProjects`);

export {
  getProjects,
  getProjectDetails,
  createProject,
  updateProject,
  deleteProject,
  createProjectPhase,
  updateProjectPhase,
  deleteProjectPhase,
  getInsights,
  getPhases,
  getPhaseDetail,
  getMultiPhaseDetail,
  updatePhaseOrder,
  exportProjectSystems,
  exportProjectMulitPhaseMatrix,
  exportPhaseSnapshot,
  createCustomNotification,
  getCustomNotifications,
  deleteCustomNotification,
  exportProjectSnapShot,
  exportProjectDepartmentSnapShot,
  getProjectDetailsDocument,
  shareSnapshot,
  shareMatrixSnapshot,
  addSnapshotFilters,
  addPhaseSnapshotFilter,
  exportProjectSystemSnapShot,
  getProjectPreferences,
  updateProjectPreferences,
  updateProjectOrder,
  getDocumentSnapshot,
  exportDocumentSnapshot,
  getCheckAssignedProject,
};

import { useSelector } from "react-redux";
import { useMemo } from "react";
import { PERMISSION_TYPES } from "utils/constants";
import _ from "lodash";

const useAllowedRoutes = (routes) => {
  const { currentUser } = useSelector((state) => state.auth);
  const { accessibleFeature: accessedFeatures } = useSelector(
    (state) => state.modulePreference
  );
  const accessibleRoutes = useMemo(() => {
    if ([PERMISSION_TYPES.superAdmin].includes(currentUser.permissions)) {
      const tempRoutes = _.cloneDeep(routes);
      const length = routes?.length;
      if (length === 8) {
        tempRoutes.splice(5, 1);
      } else if (length === 11) {
        tempRoutes.splice(8, 1);
      } else {
        return routes;
      }
      return tempRoutes;
    }
    const filteredRoutes = routes
      .filter(
        (route) =>
          (!route.feature || accessedFeatures?.includes(route.feature)) &&
          (!route.roles || route.roles.includes(currentUser.permissions))
      )
      .map((route) => {
        if (route.children) {
          const children = route.children.filter(
            (child) =>
              (!child.feature || accessedFeatures?.includes(child.feature)) &&
              (!child.roles || child.roles.includes(currentUser.permissions))
          );
          return { ...route, children };
        }
        return route;
      });
    return filteredRoutes;
  }, [routes, currentUser.permissions, accessedFeatures]);

  return accessibleRoutes;
};

export default useAllowedRoutes;

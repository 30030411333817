import _ from 'lodash';

export const Highlighted = ({text = '', highlight = ''}) => {
    if (!highlight.trim()) {
      return <span>{text}</span>
    }
    const regex = new RegExp(`(${_.escapeRegExp(highlight)})`, 'gi')
    const parts = text?.split(regex)
    return (
      <span>
         {parts?.filter(part => part)?.map((part, i) => (
             regex.test(part) ? <mark style={{backgroundColor:"#EEEEEE"}}><b key={i}>{part}</b></mark> : <span key={i}>{part}</span>
         ))}
     </span>
    )
 }
import { lazy } from "react";
import LINKS from "utils/constants/links";
import lazyRetry from "utils/helpers/lazyRetry";
import { FEATURE_FLAGS } from "../feature-flags";
import { ENVIRONMENT } from "config";
import { ReactComponent as ProjectManagement } from "icons/project management.svg";

const AddDeviation = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'AddDeviation' */ "pages/Projects/Deviations/AddDeviation"
    )
  )
);
const EditDeviation = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'EditDeviation' */ "pages/Projects/Deviations/EditDeviation"
    )
  )
);
const DeviationSettings = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'DeviationSettings' */ "pages/Projects/Deviations/DeviationSettings"
    )
  )
);
const AddDmsTemplate = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'AddDmsTemplate' */ "pages/Projects/Documents/Templates/AddDmsTemplate"
    )
  )
);
const AttachmentPreview = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'AddDeviation' */ "pages/Projects/Deviations/AttachmentPreview"
    )
  )
);
const EditDmsTemplate = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'EditDmsTemplate' */ "pages/Projects/Documents/Templates/EditDmsTemplate"
    )
  )
);
const PreviewTemplate = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'PreviewTemplate' */ "pages/Projects/Documents/Templates/PreviewTemplate"
    )
  )
);
const Dashboard = lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: 'Dashboard' */ "pages/Dashboard"))
);
const ProjectList = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: 'ProjectList' */ "pages/Projects/ProjectList")
  )
);

const AddProject = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: 'AddProject' */ "pages/Projects/AddProject")
  )
);
// const EditProject = lazy(() =>
//   lazyRetry(() =>
//     import(/* webpackChunkName: 'EditProject' */ "pages/Projects/EditProject")
//   )
// );
const EditProject = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'EditProject' */ "pages/Projects/EditProject/newIndex"
    )
  )
);
const ProjectModulePreference = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'Module Preference' */ "pages/Projects/ProjectModulePreference"
    )
  )
);
const ProjectHistory = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'ProjectHistory' */ "pages/Projects/ProjectHistory"
    )
  )
);
const ProjectPhases = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'ProjectPhases' */ "pages/Projects/ProjectPhases"
    )
  )
);
const CalendarNew = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: 'ProjectCalendar' */ "pages/Projects/Calendar")
  )
);
const ProjectDocuments = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: 'ProjectDocument' */ "pages/Projects/Documents")
  )
);
const DocumentsChangeRequest = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'DocumentChangeRequest' */ "pages/Projects/Documents/DocumentsChangeRequest"
    )
  )
);
const AddDocument = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'ProjectDocument' */ "pages/Projects/Documents/AddDocument"
    )
  )
);
const EditDocument = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'ProjectDocument' */ "pages/Projects/Documents/EditDocument"
    )
  )
);
const DocumentSettings = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'ProjectDocument' */ "pages/Projects/Documents/DocumentSettings"
    )
  )
);
const DocumentViewer = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'ProjectDocument' */ "pages/Projects/Documents/DocumentViewer"
    )
  )
);
const AdobeView = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: 'AdobeView' */ "components/AdobeView/index")
  )
);
const AddMetaSystem = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'AddMetaSystem' */ "pages/MetaSystems/AddMetaSystem"
    )
  )
);
const EditMetaSystem = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'EditMetaSystem' */ "pages/MetaSystems/EditMetaSystem"
    )
  )
);
const ReportList = lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: 'ReportList' */ "pages/Reports"))
);

// New Project Insights Routes
const ProjectInsights = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'ProjectInsights' */ "pages/Projects/ProjectInsight"
    )
  )
);

const ProjectInsightsDeliverableDetails = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'ProjectInsightsDeliverableDetails' */ "pages/Projects/ProjectInsight/InsightsDeliverableDetails"
    )
  )
);

const ProjectInsightsIndividualSystemDetails = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'ProjectInsightsIndividualSystemDetails' */ "pages/Projects/ProjectInsight/InsightsIndividualSystemDetails"
    )
  )
);

const ProjectInsightsPhaseDetails = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'ProjectHistory' */ "pages/Projects/ProjectInsight/InsightsPhaseDetails"
    )
  )
);

const ProjectInsightsDeviationDetails = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'ProjectInsightsDeviationDetails' */ "pages/Projects/ProjectInsight/InsightsDeviationDetails"
    )
  )
);

const ProjectInsightsDeliverablePerformanceDetails = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'ProjectInsightsDeliverablePerformanceDetails' */ "pages/Projects/ProjectInsight/InsightsDeliverablePerformanceDetails"
    )
  )
);

const ProjectInsightsEscalationDetails = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'ProjectInsightsEscalationDetails' */ "pages/Projects/ProjectInsight/InsightsEscalationDetails"
    )
  )
);

const ProjectInsightsOverallSystemsDetails = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'ProjectInsightsOverallSystemsDetails' */ "pages/Projects/ProjectInsight/InsightsOverallSystemsDetails"
    )
  )
);

const ProjectFinance = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: 'ProjectFinance' */ "pages/Projects/Finance")
  )
);

// Project Snapshots

const DeliverableSnapshot = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'DeliverableSnapshot' */ "pages/Projects/ProjectSnapshots/DeliverableSnapshot"
    )
  )
);

const DocumentSnapshot = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'DeliverableSnapshot' */ "pages/Projects/ProjectSnapshots/DocumentSnapshot"
    )
  )
);

const SystemSnapshot = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'SystemSnapshot' */ "pages/Projects/ProjectSnapshots/SystemSnapshot"
    )
  )
);

const DeliverableMatrix = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'DeliverableMatrix' */ "pages/Projects/ProjectSnapshots/DeliverableMatrix"
    )
  )
);

const PhaseSnapshot = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'PhaseSnapshot' */ "pages/Projects/ProjectSnapshots/PhaseSnapshot"
    )
  )
);

const ProjectSystems = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'ProjectSystems' */ "pages/Projects/ProjectSystems"
    )
  )
);

const EditDeliverable = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'EditDeliverable' */ "pages/MetaSystems/EditMetaSystem/EditDeliverable"
    )
  )
);

const EditTask = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'EditTask' */ "pages/MetaSystems/EditMetaSystem/EditTask"
    )
  )
);

const AddTask = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'AddTask' */ "pages/MetaSystems/EditMetaSystem/AddTask"
    )
  )
);

const EditEscalation = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'EditTask' */ "pages/MetaSystems/EditMetaSystem/EditEscalation"
    )
  )
);

const AddEscalation = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'EditTask' */ "pages/MetaSystems/EditMetaSystem/AddEscalation"
    )
  )
);

const AddCustomNotification = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'CustomNotificationForm' */ "pages/Projects/ProjectCustomNotification/AddCustomNotification"
    )
  )
);
const EditCustomNotification = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'CustomNotificationForm' */ "pages/Projects/ProjectCustomNotification/EditCustomNotification"
    )
  )
);

const CustomNotificationList = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'CustomNotificationList' */ "pages/Projects/ProjectCustomNotification"
    )
  )
);
const ProjectDeviations = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: 'Deviations' */ "pages/Projects/Deviations")
  )
);

const Scheduler = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'ProjectScheduler' */ "pages/Projects/ProjectScheduler"
    )
  )
);
const ProjectChangeRequests = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'ChangeRequests' */ "pages/Projects/ChangeRequests"
    )
  )
);
const AddChangeRequests = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'AddChangeRequests' */ "pages/Projects/ChangeRequests/AddChangeRequest"
    )
  )
);
const EditChangeRequests = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'EditChangeRequests' */ "pages/Projects/ChangeRequests/EditChangeRequest"
    )
  )
);
const Library = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: 'Library' */ "pages/Projects/Library")
  )
);

const FileView = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'Library File View' */ "pages/Projects/Library/components/FileView"
    )
  )
);

const PatientData = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'Patient List View' */ "pages/Projects/PatientData"
    )
  )
);

const AddPatientData = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'Add Patient Form' */ "pages/Projects/PatientData/AddPatientForm"
    )
  )
);

const EditPatientData = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'Edit Patient Form' */ "pages/Projects/PatientData/EditPatientForm"
    )
  )
);

const ProjectSettings = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'Edit Patient Form' */ "pages/Projects/PatientData/CellGlassSettings"
    )
  )
);
const ValidationTemplate = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: 'ValidationTemplate' */ "pages/MetaSystems/DigitalTemplates/ValidationTemplate"
    )
  )
);
const projectRoutes = [
  {
    path: LINKS.DASHBOARD.HREF,
    name: LINKS.DASHBOARD.TITLE,
    component: Dashboard,
    roles: LINKS.DASHBOARD.ROLES,
  },
  {
    path: LINKS.PROJECTS.HREF,
    name: LINKS.PROJECTS.TITLE,
    component: ProjectList,
  },
  {
    path: LINKS.QMS.HREF,
    name: LINKS.QMS.TITLE,
    component: ProjectList,
  },
  {
    path: LINKS.DELIVERY.HREF,
    name: LINKS.DELIVERY.TITLE,
    component: ProjectList,
  },
  {
    path: LINKS.ADD_PROJECT.HREF,
    name: LINKS.ADD_PROJECT.TITLE,
    component: AddProject,
    isNotSlide: true,
  },
  {
    path: LINKS.EDIT_PROJECT.HREF,
    name: LINKS.EDIT_PROJECT.TITLE,
    component: EditProject,
    isNotSlide: true,
  },
  {
    path: LINKS.CELL_GLASS_SETTINGS.HREF,
    name: LINKS.CELL_GLASS_SETTINGS.TITLE,
    component: ProjectSettings,
    isNotSlide: true,
  },
  {
    path: LINKS.PROJECT_MODULE_PREFERENCE.HREF,
    name: LINKS.PROJECT_MODULE_PREFERENCE.TITLE,
    component: ProjectModulePreference,
    isNotSlide: true,
  },
  {
    path: LINKS.PROJECT_HISTORY.HREF,
    name: LINKS.PROJECT_HISTORY.TITLE,
    component: ProjectHistory,
    isNotSlide: true,
  },
  {
    path: LINKS.PROJECT_INSIGHT.HREF,
    name: LINKS.PROJECT_INSIGHT.TITLE,
    feature: FEATURE_FLAGS.Insights,
    component: ProjectInsights,
    isNotSlide: true,
  },
  {
    path: LINKS.PROJECT_INSIGHTS_PHASE_DETAILS.HREF,
    name: LINKS.PROJECT_INSIGHTS_PHASE_DETAILS.TITLE,
    feature: FEATURE_FLAGS.Insights,
    component: ProjectInsightsPhaseDetails,
    isNotSlide: true,
  },
  {
    path: LINKS.PROJECT_INSIGHTS_ESCALATION_TABLES_DETAILS.HREF,
    name: LINKS.PROJECT_INSIGHTS_ESCALATION_TABLES_DETAILS.TITLE,
    feature: FEATURE_FLAGS.Insights,
    component: ProjectInsightsEscalationDetails,
    isNotSlide: true,
  },
  {
    path: LINKS.PROJECT_INSIGHTS_DELIVERABLE_PERFORMANCE_DETAILS.HREF,
    name: LINKS.PROJECT_INSIGHTS_DELIVERABLE_PERFORMANCE_DETAILS.TITLE,
    feature: FEATURE_FLAGS.Insights,
    component: ProjectInsightsDeliverablePerformanceDetails,
    isNotSlide: true,
  },
  {
    path: LINKS.PROJECT_INSIGHTS_DEVIATION_PERFORMANCE_DETAILS.HREF,
    name: LINKS.PROJECT_INSIGHTS_DEVIATION_PERFORMANCE_DETAILS.TITLE,
    feature: FEATURE_FLAGS.Insights,
    component: ProjectInsightsDeviationDetails,
    isNotSlide: true,
  },
  {
    path: LINKS.PROJECT_INSIGHTS_OVERALL_SYSTEMS_DETAILS.HREF,
    name: LINKS.PROJECT_INSIGHTS_OVERALL_SYSTEMS_DETAILS.TITLE,
    feature: FEATURE_FLAGS.Insights,
    component: ProjectInsightsOverallSystemsDetails,
    isNotSlide: true,
  },
  {
    path: LINKS.PROJECT_INSIGHTS_SYSTEM_DETAILS.HREF,
    name: LINKS.PROJECT_INSIGHTS_SYSTEM_DETAILS.TITLE,
    feature: FEATURE_FLAGS.Insights,
    component: ProjectInsightsIndividualSystemDetails,
    isNotSlide: true,
  },
  {
    path: LINKS.PROJECT_INSIGHTS_DELIVERABLES_DETAILS.HREF,
    name: LINKS.PROJECT_INSIGHTS_DELIVERABLES_DETAILS.TITLE,
    feature: FEATURE_FLAGS.Insights,
    component: ProjectInsightsDeliverableDetails,
    isNotSlide: true,
  },
  {
    path: LINKS.DELIVERABLE_SNAPSHOT.HREF,
    name: LINKS.DELIVERABLE_SNAPSHOT.TITLE,
    component: DeliverableSnapshot,
    feature: FEATURE_FLAGS.DeliverableSnapshot,
    isNotSlide: true,
  },
  {
    path: LINKS.DOCUMENT_SNAPSHOT.HREF,
    name: LINKS.DOCUMENT_SNAPSHOT.TITLE,
    component: DocumentSnapshot,
    feature: FEATURE_FLAGS.DocumentSnapshot,
    isNotSlide: true,
  },
  {
    path: LINKS.SYSTEM_SNAPSHOT.HREF,
    name: LINKS.SYSTEM_SNAPSHOT.TITLE,
    component: SystemSnapshot,
    feature: FEATURE_FLAGS.SystemSnapshot,
    isNotSlide: true,
  },
  {
    path: LINKS.PHASE_SNAPSHOT.HREF,
    name: LINKS.PHASE_SNAPSHOT.TITLE,
    component: PhaseSnapshot,
    feature: FEATURE_FLAGS.PhaseSnapshot,
    isNotSlide: true,
  },
  {
    path: LINKS.DELIVERABLE_MATRIX.HREF,
    name: LINKS.DELIVERABLE_MATRIX.TITLE,
    component: DeliverableMatrix,
    feature: FEATURE_FLAGS.DeliverableMatrix,
    isNotSlide: true,
  },
  {
    path: LINKS.PROJECT_SCHEDULER.HREF,
    name: LINKS.PROJECT_SCHEDULER.TITLE,
    feature: FEATURE_FLAGS.Scheduler,
    component: Scheduler,
    isNotSlide: true,
  },
  {
    path: LINKS.PROJECT_SYSTEMS.HREF,
    name: LINKS.PROJECT_SYSTEMS.TITLE,
    component: ProjectSystems,
    feature: FEATURE_FLAGS.Systems,
    isNotSlide: true,
  },
  {
    path: LINKS.PROJECT_PHASES.HREF,
    name: LINKS.PROJECT_PHASES.TITLE,
    feature: FEATURE_FLAGS.Phases,
    component: ProjectPhases,
    isNotSlide: true,
  },
  {
    path: LINKS.PROJECT_CALENDAR.HREF,
    name: LINKS.PROJECT_CALENDAR.TITLE,
    component: CalendarNew,
    feature: FEATURE_FLAGS.CalendarView,
    isNotSlide: true,
  },
  {
    path: LINKS.PROJECT_DOCUMENTS.HREF,
    name: LINKS.PROJECT_DOCUMENTS.TITLE,
    component: ProjectDocuments,
    feature: FEATURE_FLAGS.DocumentManagement,
    isNotSlide: true,
  },
  {
    path: LINKS.DOCUMENTS_CHANGE_REQUEST.HREF,
    name: LINKS.DOCUMENTS_CHANGE_REQUEST.TITLE,
    component: DocumentsChangeRequest,
    feature: FEATURE_FLAGS.DocumentManagement,
    isNotSlide: true,
  },
  {
    path: LINKS.ADD_DOCUMENT.HREF,
    name: LINKS.ADD_DOCUMENT.TITLE,
    component: AddDocument,
    feature: FEATURE_FLAGS.DocumentManagement,
    isNotSlide: true,
  },
  {
    path: LINKS.EDIT_DOCUMENTS.HREF,
    name: LINKS.EDIT_DOCUMENTS.TITLE,
    feature: FEATURE_FLAGS.DocumentManagement,
    component: EditDocument,
    isNotSlide: true,
  },
  {
    path: LINKS.VIEW_DOCUMENTS.HREF,
    name: LINKS.VIEW_DOCUMENTS.TITLE,
    component: DocumentViewer,
    feature: FEATURE_FLAGS.DocumentManagement,
    isNotSlide: true,
  },
  {
    path: LINKS.ADOBE_VIEW.HREF,
    name: LINKS.ADOBE_VIEW.TITLE,
    component: AdobeView,
    feature: FEATURE_FLAGS.DocumentManagement,
    isNotSlide: true,
  },
  {
    path: LINKS.DOCUMENT_SETTINGS.HREF,
    name: LINKS.DOCUMENT_SETTINGS.TITLE,
    feature: FEATURE_FLAGS.DocumentManagement,
    component: DocumentSettings,
    isNotSlide: true,
  },
  {
    path: LINKS.ADD_META_SYSTEM.HREF,
    name: LINKS.ADD_META_SYSTEM.TITLE,
    feature: FEATURE_FLAGS.Systems,
    component: AddMetaSystem,
    isNotSlide: true,
  },
  {
    path: LINKS.EDIT_META_SYSTEM.HREF,
    name: LINKS.EDIT_META_SYSTEM.TITLE,
    component: EditMetaSystem,
    feature: FEATURE_FLAGS.Systems,
    isNotSlide: true,
  },
  {
    path: LINKS.EDIT_META_SYSTEM_INCOMPLETE.HREF,
    name: LINKS.EDIT_META_SYSTEM_INCOMPLETE.TITLE,
    component: EditMetaSystem,
    feature: FEATURE_FLAGS.Systems,
    isNotSlide: true,
  },
  {
    path: LINKS.EDIT_DELIVERABLE.HREF,
    name: LINKS.EDIT_DELIVERABLE.TITLE,
    feature: FEATURE_FLAGS.Deliverables,
    component: EditDeliverable,
    isNotSlide: true,
  },
  {
    path: LINKS.EDIT_TASK.HREF,
    name: LINKS.EDIT_TASK.TITLE,
    component: EditTask,
    feature: FEATURE_FLAGS.Tasks,
    isNotSlide: true,
  },
  {
    path: LINKS.ADD_TASK.HREF,
    name: LINKS.ADD_TASK.TITLE,
    component: AddTask,
    feature: FEATURE_FLAGS.Tasks,
    isNotSlide: true,
  },
  {
    path: LINKS.EDIT_ESCALATION.HREF,
    name: LINKS.EDIT_ESCALATION.TITLE,
    component: EditEscalation,
    feature: FEATURE_FLAGS.Escalations,
    isNotSlide: true,
  },
  {
    path: LINKS.ADD_ESCALATION.HREF,
    name: LINKS.ADD_ESCALATION.TITLE,
    component: AddEscalation,
    feature: FEATURE_FLAGS.Escalations,
    isNotSlide: true,
  },
  {
    path: LINKS.REPORTS.HREF,
    name: LINKS.REPORTS.TITLE,
    component: ReportList,
    roles: LINKS.REPORTS.ROLES,
  },
  {
    path: LINKS.NOTIFICATION_TEMPLATE.HREF,
    name: LINKS.NOTIFICATION_TEMPLATE.TITLE,
    component: CustomNotificationList,
    isNotSlide: true,
  },
  {
    path: LINKS.EDIT_NOTIFICATION_TEMPLATE.HREF,
    name: LINKS.EDIT_NOTIFICATION_TEMPLATE.TITLE,
    component: EditCustomNotification,
    isNotSlide: true,
  },
  {
    path: LINKS.ADD_NOTIFICATION_TEMPLATE.HREF,
    name: LINKS.ADD_NOTIFICATION_TEMPLATE.TITLE,
    component: AddCustomNotification,
    isNotSlide: true,
  },
  {
    path: LINKS.PROJECT_DEVIATIONS.HREF,
    name: LINKS.PROJECT_DEVIATIONS.TITLE,
    component: ProjectDeviations,
    feature: FEATURE_FLAGS.DeviationManagement,
    isNotSlide: true,
  },
  {
    path: LINKS.EDIT_DEVIATIONS.HREF,
    name: LINKS.EDIT_DEVIATIONS.TITLE,
    feature: FEATURE_FLAGS.DeviationManagement,
    component: EditDeviation,
    isNotSlide: true,
  },
  {
    path: LINKS.DEVIATION_SETTINGS.HREF,
    name: LINKS.DEVIATION_SETTINGS.TITLE,
    feature: FEATURE_FLAGS.DeviationManagement,
    component: DeviationSettings,
    isNotSlide: true,
  },
  {
    path: LINKS.PREVIEW_DEVIATION_ATTACHMENT.HREF,
    name: LINKS.PREVIEW_DEVIATION_ATTACHMENT.TITLE,
    feature: FEATURE_FLAGS.DeviationManagement,
    component: AttachmentPreview,
    isNotSlide: true,
  },
  {
    path: LINKS.ADD_DEVIATION.HREF,
    name: LINKS.ADD_DEVIATION.TITLE,
    feature: FEATURE_FLAGS.DeviationManagement,
    component: AddDeviation,
    isNotSlide: true,
  },
  {
    path: LINKS.PROJECT_CHANGE_REQUEST.HREF,
    name: LINKS.PROJECT_CHANGE_REQUEST.TITLE,
    component: ProjectChangeRequests,
    feature: FEATURE_FLAGS.ChangeRequestManagement,
    isNotSlide: true,
  },
  {
    path: LINKS.EDIT_CHANGE_REQUEST.HREF,
    name: LINKS.EDIT_CHANGE_REQUEST.TITLE,
    feature: FEATURE_FLAGS.ChangeRequestManagement,
    component: EditChangeRequests,
    isNotSlide: true,
  },
  {
    path: LINKS.ADD_CHANGE_REQUEST.HREF,
    name: LINKS.ADD_CHANGE_REQUEST.TITLE,
    feature: FEATURE_FLAGS.ChangeRequestManagement,
    component: AddChangeRequests,
    isNotSlide: true,
  },
  {
    path: LINKS.ADD_DMS_TEMPLATE.HREF,
    name: LINKS.ADD_DMS_TEMPLATE.TITLE,
    component: AddDmsTemplate,
    isNotSlide: true,
  },
  {
    path: LINKS.EDIT_DMS_TEMPLATE.HREF,
    name: LINKS.EDIT_DMS_TEMPLATE.TITLE,
    component: EditDmsTemplate,
    isNotSlide: true,
  },
  {
    path: LINKS.PREVIEW_DMS_TEMPLATE.HREF,
    name: LINKS.PREVIEW_DMS_TEMPLATE.TITLE,
    component: PreviewTemplate,
    isNotSlide: true,
  },
  {
    path: LINKS.PROJECT_FINANCE.HREF,
    name: LINKS.PROJECT_FINANCE.TITLE,
    feature: FEATURE_FLAGS.Finance,
    component: ProjectFinance,
    isNotSlide: true,
  },
  {
    path: LINKS.LIBRARY.HREF,
    name: LINKS.LIBRARY.TITLE,
    feature: FEATURE_FLAGS.Library,
    component: Library,
    isNotSlide: true,
  },
  {
    path: LINKS.LIBRARY_NESTED.HREF,
    name: LINKS.LIBRARY_NESTED.TITLE,
    feature: FEATURE_FLAGS.Library,
    component: Library,
    isNotSlide: true,
  },
  {
    path: LINKS.LIBRARY_CATEGORY_NESTED.HREF,
    name: LINKS.LIBRARY_CATEGORY_NESTED.TITLE,
    feature: FEATURE_FLAGS.Library,
    component: Library,
    isNotSlide: true,
  },
  {
    path: LINKS.LIBRARY_FILE_VIEW.HREF,
    name: LINKS.LIBRARY_FILE_VIEW.TITLE,
    feature: FEATURE_FLAGS.Library,
    component: FileView,
    isNotSlide: true,
  },
  {
    path: LINKS.PATIENT_LIST.HREF,
    name: LINKS.PATIENT_LIST.TITLE,
    component: PatientData,
    isNotSlide: true,
  },
  {
    path: LINKS.ADD_PATIENT.HREF,
    name: LINKS.ADD_PATIENT.TITLE,
    component: AddPatientData,
    isNotSlide: true,
  },
  {
    path: LINKS.EDIT_PATIENT.HREF,
    name: LINKS.EDIT_PATIENT.TITLE,
    component: EditPatientData,
    isNotSlide: true,
  },
  {
    path: LINKS.EDIT_PATIENT.HREF,
    name: LINKS.EDIT_PATIENT.TITLE,
    component: EditPatientData,
    isNotSlide: true,
  },
  {
    path: LINKS.DIGITAL_TEMPLATE.HREF,
    name: LINKS.DIGITAL_TEMPLATE.TITLE,
    component: ValidationTemplate,
    isNotSlide: true,
  },
];

if (ENVIRONMENT?.toLowerCase() === "qms") {
  projectRoutes.splice(1, 1);
} else {
  projectRoutes.splice(2, 2);
}

const projectManagementRoutes = Object.freeze({
  id: LINKS.PROJECT_MANAGEMENT.TITLE,
  path: LINKS.PROJECT_MANAGEMENT.HREF,
  icon: <ProjectManagement />,
  containsHome: true,
  component: null,
  children: projectRoutes,
  roles: LINKS.PROJECT_MANAGEMENT.ROLES,
});

export default projectManagementRoutes;

import { memo } from "react";

const PI = 3.14;

const CustomHourCircle = ({
  size = 50,
  textSize = size / 4,
  progressColor,
  trackColor,
  width = 4,
  progressValue,
  showTextPercentage,
  textColor = "black",
  secondaryTextSize = size / 4,
  secondaryText,
  roundedCorners,
  className,
}) => {
  const divisions = 112;
  const progressOffsetPercentage = (divisions - progressValue) / divisions;
  const trackOffsetPercentage = (divisions - (100 - progressValue)) / divisions;
  const quaterOffset = 360 / 112;
  const radius = size / 2 - width / 2;
  const circumference = 2 * PI * radius;
  const progressOffset = circumference * progressOffsetPercentage;
  const trackOffset = circumference * trackOffsetPercentage;
  return (
    <div
      className={`d-jsb-cen pos-rel ${className}`}
      style={{
        height: size,
        width: size,
      }}
    >
      <svg width={`${size}`} height={`${size}`} viewBox={`0 0 ${size} ${size}`}>
        {progressValue > 0 && progressValue < 100 && (
          <>
            <circle
              r={`${radius}`}
              cx={`${size / 2}`}
              cy={`${size / 2}`}
              fill="transparent"
              stroke={progressColor}
              stroke-width={width}
              stroke-linecap={roundedCorners ? "round" : "square"}
              stroke-dasharray={`${circumference}px`}
              stroke-dashoffset={`${progressOffset}px`}
              transform={`rotate(-${90 - 3 * quaterOffset},${size / 2},${
                size / 2
              })`}
            ></circle>

            <circle
              r={`${radius}`}
              cx={`${size / 2}`}
              cy={`${size / 2}`}
              fill="transparent"
              stroke={trackColor}
              stroke-linecap={roundedCorners ? "round" : "square"}
              stroke-width={width}
              stroke-dasharray={`${circumference}px`}
              stroke-dashoffset={`-${trackOffset}px`}
              transform={`rotate(-${90 + 3 * quaterOffset},${size / 2},${
                size / 2
              })`}
            ></circle>
          </>
        )}

        {progressValue === 100 && (
          <circle
            r={`${radius}`}
            cx={`${size / 2}`}
            cy={`${size / 2}`}
            fill="transparent"
            stroke={progressColor}
            stroke-width={width}
            stroke-linecap={roundedCorners ? "round" : "square"}
            stroke-dasharray={`${circumference}px`}
            stroke-dashoffset={`0px`}
          ></circle>
        )}

        {progressValue === 0 && (
          <circle
            r={`${radius}`}
            cx={`${size / 2}`}
            cy={`${size / 2}`}
            fill="transparent"
            stroke={trackColor}
            stroke-linecap={roundedCorners ? "round" : "square"}
            stroke-width={width}
            stroke-dasharray={`${circumference}px`}
            stroke-dashoffset={`0px`}
          ></circle>
        )}
      </svg>
      {(showTextPercentage || secondaryText) && (
        <div
          className="fw-bold "
          style={{
            textAlign: "center",
            position: "absolute",
            color: textColor,
          }}
        >
          {showTextPercentage && (
            <div
              style={{
                fontSize: textSize || size / 4,
              }}
            >
              {progressValue}%
            </div>
          )}
          {secondaryText && (
            <div
              style={{
                fontSize: secondaryTextSize || size / 4,
              }}
            >
              {secondaryText}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default memo(CustomHourCircle);

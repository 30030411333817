import React, { memo } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import Helmet from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Link,
  Typography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { milestoneDiamond } from "pages/MetaSystems/Shared/DeliverablesTableNew/deliverablesList";
import { colorRA } from "pages/Projects/ProjectSnapshots/DeliverableSnapshot/snapshotHelper";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Divider = styled(MuiDivider)(spacing);

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const PageHeader = ({
  title,
  links = [],
  leftElement,
  milestone = false,
  completion,
  end,
  status,
  type = "",
}) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Helmet title={title} />

      <div className={classes.root}>
        <div>
          {milestone &&
            milestoneDiamond("medium", colorRA(completion, end, status, type))}
          <Typography variant="h3" gutterBottom display="inline">
            {title}
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            {links.map(
              (link = { HREF: "CHECK_CODE", TITLE: "CHECK_CODE" }, index) => (
                <Link
                  exact
                  key={index}
                  component={NavLink}
                  to={link.HREF}
                  children={<> {link.TITLE}</>}
                />
              )
            )}
            <Typography>{title}</Typography>
          </Breadcrumbs>
        </div>

        {leftElement}
      </div>

      <Divider my={2} />
    </React.Fragment>
  );
};

export default memo(PageHeader);

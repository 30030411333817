import React, { memo, useRef, useState } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Button,
  Grid,
  LinearProgress,
  IconButton,
  Divider,
  Chip,
  FormControl,
  InputLabel,
  TextField,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Link,
  Tooltip,
  Typography,
  Popover,
  CircularProgress,
} from "@material-ui/core";
import styled from "styled-components/macro";
import Select from "react-select";
import moment from "moment";
import { POPUP_TYPE, noop } from "utils/constants";
import { Close, DescriptionRounded } from "@material-ui/icons";
import { ReactComponent as OpenInNew } from "icons/open_in_new.svg";
import { ReactComponent as UploadIcon } from "icons/upload.svg";
import { ReactComponent as ClockIcon } from "icons/clock.svg";

import { colorRA } from "pages/Projects/ProjectSnapshots/DeliverableSnapshot/snapshotHelper";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DatePicker } from "@material-ui/pickers";
import _ from "lodash";
import { DocumentHelper } from "pages/Projects/Documents/helpers";
import { useHistory, useParams } from "react-router-dom";
import LINKS from "utils/constants/links";
// import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import {
  dateRangeError,
  errorMessage,
} from "pages/MetaSystems/EditMetaSystem/EditDeliverable";
import { DateRange } from "react-date-range";
import FeatureFlag from "utils/hocs/FeatureFlag";
import { FEATURE_FLAGS } from "utils/constants/feature-flags";
import { PERMISSION_TYPES } from "utils/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  customDayContent,
  getHolidayAdjustedEndDate,
} from "utils/helpers/dateHelper";
import { milestoneDiamond } from "../deliverablesList";
import { uploadSingleFile } from "services/api-general";
import { setPopup } from "redux/actions/popupActions";
import DocumentViewer from "components/DocumentViewer";
import { ReactComponent as RescheduleIcon } from "icons/reschedule_icon.svg";
import ScheduleHistoryDialog from "./ScheduleHistoryDialog";
import { DeviationHelper } from "pages/Projects/Deviations/helpers";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "20px",
    backgroundColor: "#d3e7e9",
    color: "black",
    marginTop: "5px",
    marginBottom: "10px",
  },
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
}));

const EditDeliverable = ({
  permissions,
  deliverable = {},
  deliverables = [],
  departments = [],
  users = [],
  projectId = null,
  handleDataChange = noop,
  close = noop,
  getNamebyId = noop,
  onRowChange = noop,
  showNotes = noop,
  getDepartmentUsers = noop,
  getUsers = noop,
  getDepartments = noop,
  excluded = false,
  project = {},
  isWorkflowCall,
  handleDeliverablSave,
  handleOpenTimeEntryModal,
  deviationList = [],
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [error, setError] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [openFileView, setOpenFileView] = useState(false);
  const [openScheduleHistory, setOpenScheduleHistory] = useState(false);
  const [anchorElDates, setAnchorElDates] = useState({
    draftStart: null,
    draftEnd: null,
    ifrStart: null,
    ifrEnd: null,
    reviewStart: null,
    reviewEnd: null,
    ifaStart: null,
    ifaEnd: null,
    approvalStart: null,
    approvalEnd: null,
    masterStart: null,
    masterEnd: null,
    deliverableStart: null,
    deliverableEnd: null,
  });
  const [dateRanges, setDateRanges] = useState({});
  const [expectedDateRange, setExpectedDateRange] = useState({
    startDate: moment(new Date()),
    endDate: moment(new Date()),
  });
  const { mainSystemId, systemId } = useParams();
  const fileInput = useRef();
  const dispatch = useDispatch();
  const { DOC_STATUS } = DocumentHelper();
  const { DEVIATION_STATUS } = DeviationHelper();

  const organization = useSelector((state) =>
    state.organizations.results.find(
      (result) => result._id === project.organization
    )
  );

  const getType = () => {
    return [
      { value: "DOCUMENTATION", label: "Documentation" },
      { value: "EXECUTION", label: "Execution" },
    ];
  };
  const getDefaultType = (type) => {
    return { value: type, label: type?.toUpperCase() };
  };

  const checkValidation = (type) => {
    const checkDate = (date) => {
      return moment(date?.split("T")[0])?.isBetween(
        moment(deliverable?.start?.split("T")[0]),
        moment(deliverable?.end?.split("T")[0]),
        undefined,
        "[]"
      );
    };
    if (
      (!checkDate(deliverable?.draft?.end) || !deliverable?.draft?.end) &&
      type === "draftEnd"
    ) {
      return true;
    }
    if (
      (!checkDate(deliverable?.ifr?.start) || !deliverable?.ifr?.start) &&
      type === "ifrStart"
    ) {
      return true;
    }
    if (
      (!checkDate(deliverable?.ifr?.end) || !deliverable?.ifr?.end) &&
      type === "ifrEnd"
    ) {
      return true;
    }
    if (
      (!checkDate(deliverable?.ifa?.start) || !deliverable?.ifa?.start) &&
      type === "ifaStart"
    ) {
      return true;
    }
    if (
      (!checkDate(deliverable?.ifa?.end) || !deliverable?.ifa?.end) &&
      type === "ifaEnd"
    ) {
      return true;
    }
    if (
      (!checkDate(deliverable?.review?.start) || !deliverable?.review?.start) &&
      type === "reviewStart"
    ) {
      return true;
    }
    if (
      (!checkDate(deliverable?.review?.end) || !deliverable?.review?.end) &&
      type === "reviewEnd"
    ) {
      return true;
    }
    if (
      (!checkDate(deliverable?.approval?.start) ||
        !deliverable?.approval?.start) &&
      type === "approvalStart"
    ) {
      return true;
    }
    if (
      (!checkDate(deliverable?.approval?.end) || !deliverable?.approval?.end) &&
      type === "approvalEnd"
    ) {
      return true;
    }
    if (
      type === "ifrIssued" &&
      deliverable?.ifr?.issued &&
      Math.floor(deliverable?.draft?.status || 0) < 100
    ) {
      return true;
    }
    if (
      type === "ifaIssued" &&
      deliverable?.ifa?.issued &&
      Math.floor(deliverable?.review?.status || 0) < 100
    ) {
      return true;
    }
    if (
      type === "reviewStatus" &&
      Math.round(deliverable?.review?.status) > 0 &&
      !deliverable.ifr.issued &&
      (deliverable?.doc?.length || 0) === 0
    ) {
      return true;
    }
    if (
      type === "approvalStatus" &&
      Math.round(deliverable?.approval?.status) > 0 &&
      !deliverable.ifa.issued &&
      (deliverable?.doc?.length || 0) === 0
    ) {
      return true;
    }
    return false;
  };

  if (deliverable.draft === undefined) {
    deliverable["draft"] = {};
  }
  if (deliverable.ifa === undefined) {
    deliverable["ifa"] = {};
  }
  if (deliverable.ifr === undefined) {
    deliverable["ifr"] = {};
  }
  if (deliverable.review === undefined) {
    deliverable["review"] = {};
  }
  if (deliverable.approval === undefined) {
    deliverable["approval"] = {};
  }

  if (!deliverable?.draft?.start) {
    deliverable["draft"]["start"] = deliverable?.start;
  }
  if (!deliverable?.approval?.end) {
    deliverable["approval"]["end"] = deliverable?.end;
  }

  const handleSave = () => {
    if (isWorkflowCall) {
      handleDeliverablSave(deliverable, false);
    }
    setError(true);
    const formValidated = !(
      checkValidation("draftEnd") ||
      checkValidation("reviewStart") ||
      checkValidation("reviewEnd") ||
      checkValidation("ifrStart") ||
      checkValidation("ifrEnd") ||
      checkValidation("ifaStart") ||
      checkValidation("ifaEnd") ||
      checkValidation("approvalStart") ||
      checkValidation("approvalEnd") ||
      checkValidation("ifrIssued") ||
      checkValidation("ifaIssued") ||
      checkValidation("reviewStatus") ||
      checkValidation("approvalStatus")
    );

    if (formValidated || deliverable.type === "EXECUTION") {
      onRowChange();
    }
  };

  const openCalendarPopup = (event, type, variant) => {
    setDateRanges([
      {
        startDate:
          type === "deliverable" || variant === "draftStart"
            ? new Date(moment(deliverable.start.split("T")[0]))
            : new Date(moment(deliverable[type].start.split("T")[0])),
        endDate:
          type === "deliverable" || variant === "approvalEnd"
            ? new Date(moment(deliverable.end.split("T")[0]))
            : new Date(moment(deliverable[type].end.split("T")[0])),
        key: "selection",
      },
    ]);
    let range = { ...anchorElDates };
    range[variant] = event.currentTarget;
    setAnchorElDates(range);
  };

  const getMinimumDate = (varaint) => {
    if (project?.autoSchedulerEnabled) {
      switch (varaint) {
        case "ifrStart":
        case "ifrEnd":
          return (
            new Date(moment(deliverable?.draft?.end?.split("T")[0])) || null
          );
        case "reviewStart":
        case "reviewEnd":
          return new Date(moment(deliverable?.ifr?.end?.split("T")[0])) || null;
        case "ifaStart":
        case "ifaEnd":
          return (
            new Date(moment(deliverable?.review?.end?.split("T")[0])) || null
          );

        case "approvalStart":
          return new Date(moment(deliverable?.ifa?.end?.split("T")[0])) || null;
        default:
          return new Date(moment(deliverable?.start?.split("T")[0])) || null;
      }
    } else {
      return new Date(moment(deliverable?.start?.split("T")[0])) || null;
    }
  };

  const dateRangeSelector = (type, variant) => {
    return (
      <div>
        <TextField
          error={checkValidation(variant)}
          InputProps={{
            readOnly:
              [PERMISSION_TYPES.user, PERMISSION_TYPES.viewer].includes(
                permissions
              ) ||
              variant === "draftStart" ||
              variant === "approvalEnd",
          }}
          required
          label={variant.includes("Start") ? "Start date" : "End date"}
          aria-describedby={
            Boolean(anchorElDates[variant]) ? "simple-popover" : undefined
          }
          onClick={(event) => {
            if (
              ![PERMISSION_TYPES.user, PERMISSION_TYPES.viewer].includes(
                permissions
              )
            ) {
              openCalendarPopup(event, type, variant);
            }
          }}
          value={
            type === "deliverable"
              ? moment(
                  deliverable?.[
                    variant.includes("Start") ? "start" : "end"
                  ]?.split("T")[0]
                ).format("MM/DD/YY")
              : moment(
                  deliverable[type]?.[
                    variant.includes("Start") ? "start" : "end"
                  ]?.split("T")[0]
                ).format("MM/DD/YY")
          }
        ></TextField>
        {type !== "deliverable" &&
          checkValidation(variant) &&
          dateRangeError(type, variant, deliverable)}
        {variant !== "draftStart" && variant !== "approvalEnd" && (
          <Popover
            id={Boolean(anchorElDates[variant]) ? "simple-popover" : undefined}
            open={Boolean(anchorElDates[variant])}
            anchorEl={anchorElDates[variant]}
            onClose={() => {
              setAnchorElDates({
                ...anchorElDates,
                [variant]: null,
              });
              setExpectedDateRange({
                startDate: null,
                endDate: null,
              });
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div>
              {type !== "deliverable" && (
                <DateRange
                  editableDateInputs={true}
                  disabledDates={organization?.organizationHoliday?.map(
                    (holiday) =>
                      new Date(
                        moment(holiday).format("YYYY-MM-DDT00:00:00.000[Z]")
                      )
                  )}
                  maxDate={
                    new Date(moment(deliverable?.end?.split("T")[0])) || null
                  }
                  minDate={getMinimumDate(variant)}
                  onChange={({ selection }) => {
                    const adjustedEndDate = new Date(
                      getHolidayAdjustedEndDate(
                        type === "draft"
                          ? deliverable.draft.start.split("T")[0]
                          : selection.startDate,
                        deliverable[type + "Duration"],
                        organization?.organizationHoliday
                      )
                    );
                    setDateRanges([
                      {
                        startDate:
                          type === "draft"
                            ? new Date(
                                moment(deliverable.draft.start.split("T")[0])
                              )
                            : new Date(moment(selection.startDate)),
                        endDate: project.autoSchedulerEnabled
                          ? adjustedEndDate
                          : type === "approval"
                          ? new Date(
                              moment(deliverable.approval.end.split("T")[0])
                            )
                          : new Date(moment(selection.endDate)),
                        key: "selection",
                      },
                    ]);
                  }}
                  moveRangeOnFirstSelection={false}
                  ranges={dateRanges}
                />
              )}
              {type === "deliverable" && (
                <DateRange
                  editableDateInputs={true}
                  disabledDates={organization?.organizationHoliday?.map(
                    (holiday) =>
                      new Date(
                        moment(holiday).format("YYYY-MM-DDT00:00:00.000[Z]")
                      )
                  )}
                  dayContentRenderer={(day) =>
                    customDayContent(day, expectedDateRange)
                  }
                  onChange={({ selection }) => {
                    const adjustedEndDate = new Date(
                      getHolidayAdjustedEndDate(
                        selection.startDate,
                        deliverable.duration,
                        organization?.organizationHoliday
                      )
                    );
                    setDateRanges([
                      {
                        startDate: new Date(moment(selection.startDate)),
                        endDate: new Date(moment(selection.endDate)),
                        key: "selection",
                      },
                    ]);
                    setExpectedDateRange({
                      startDate: new Date(moment(selection.startDate)),
                      endDate: new Date(moment(adjustedEndDate)),
                    });
                  }}
                  moveRangeOnFirstSelection={false}
                  ranges={dateRanges}
                />
              )}
            </div>
            <div className="d-jsb-r mb-5">
              <Button
                variant="contained"
                onClick={() =>
                  setAnchorElDates({
                    ...anchorElDates,
                    [variant]: null,
                  })
                }
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                className="ml-5 mr-5"
                onClick={() => {
                  if (type !== "deliverable") {
                    handleDataChange({
                      target: {
                        type: "date",
                        value: dateRanges[0].startDate,
                        name: type + "Start",
                      },
                    });
                    handleDataChange({
                      target: {
                        type: "date",
                        value: dateRanges[0].endDate,
                        name: type + "End",
                      },
                    });
                  } else {
                    handleDataChange({
                      target: {
                        type: "date",
                        value: dateRanges[0].startDate,
                        value1: dateRanges[0].endDate,
                        name: "startEnd",
                      },
                    });
                  }

                  setAnchorElDates({
                    ...anchorElDates,
                    [variant]: null,
                  });
                }}
              >
                Save
              </Button>
            </div>
          </Popover>
        )}
      </div>
    );
  };

  const upload = async (file) => {
    setFileLoading(true);
    let formData = new FormData();
    formData.append("file", file);
    formData.append("module", "deliverable");
    formData.append("projectId", projectId);
    await uploadSingleFile(formData)
      .then((resp) => {
        handleDataChange({
          target: {
            type: "file",
            value: resp.data,
            name: "masterFile",
          },
        });
        setFileLoading(false);
      })
      .catch((err) => {
        setFileLoading(false);
        const errMessage = err.response?.data?.info || "File Error";
        dispatch(
          setPopup({
            popupType: POPUP_TYPE.error,
            popupText: errMessage,
          })
        );
        console.log("error >>>>> ", err);
      });
  };

  const openFile = () => {
    setOpenFileView(true);
  };

  const handleCapture = ({ target }) => {
    if (target.files[0]) {
      upload(target.files[0]);
    }
  };

  const handleRemoveMasterFile = () => {
    handleDataChange({
      target: {
        type: "file",
        value: null,
        name: "removeMasterFile",
      },
    });
  };

  const handleCloseScheduleHistory = () => {
    setOpenScheduleHistory(false);
  };

  return (
    <Card>
      <CardHeader
        title={
          <div>
            {deliverable?.milestone &&
              milestoneDiamond(
                "small",
                colorRA(
                  deliverable?.completion,
                  deliverable?.end,
                  deliverable?.status,
                  "timeline",
                  isWorkflowCall
                )
              )}{" "}
            {deliverable.name}
          </div>
        }
        action={
          <div>
            {!isWorkflowCall && (
              <FeatureFlag feature={FEATURE_FLAGS.WorkloadEntry}>
                <IconButton onClick={() => handleOpenTimeEntryModal()}>
                  <ClockIcon />
                </IconButton>
              </FeatureFlag>
            )}
            {!isWorkflowCall && (
              <IconButton onClick={() => showNotes()}>
                <DescriptionRounded
                  className={
                    deliverable?.notes?.length > 0 ? "txt-black" : "txt-grey"
                  }
                />
              </IconButton>
            )}
            {!isWorkflowCall && (
              <IconButton
                onClick={() => {
                  history.push(
                    LINKS.EDIT_DELIVERABLE.HREF.replace(":systemId", systemId)
                      .replace(":mainSystemId", mainSystemId)
                      .replace(":projectId", projectId)
                      .replace(":deliverableId", deliverable._id)
                  );
                }}
              >
                {/* <OpenInNewIcon /> */}
                <OpenInNew />
              </IconButton>
            )}
            <IconButton onClick={() => close()}>
              <Close />
            </IconButton>
          </div>
        }
      />
      <CardContent>
        <form noValidate>
          <div className="max-ht-620 scroll-y">
            {/* ------------- input forms start ------------- */}
            <Grid container columns={12} justify="space-between">
              <div className="wd-48">
                <InputLabel id="demo-multiple-chip-label">
                  Department
                </InputLabel>
                <FormControl fullWidth className="mb-10 mt-5">
                  <Select
                    maxMenuHeight={140}
                    value={deliverable.defaultDepartment}
                    isDisabled={excluded}
                    options={getDepartments()}
                    onChange={(selected) =>
                      handleDataChange({
                        target: { value: selected.value, name: "department" },
                      })
                    }
                  />
                </FormControl>
              </div>
              <div className="wd-48">
                <InputLabel id="demo-multiple-chip-label">Type</InputLabel>
                <FormControl fullWidth className="mb-10 mt-5">
                  <Select
                    value={getDefaultType(deliverable?.type)}
                    isDisabled={excluded}
                    options={getType()}
                    onChange={(selected) => {
                      handleDataChange({
                        target: { value: selected.value, name: "type" },
                      });
                    }}
                  />
                </FormControl>
              </div>
              {!isWorkflowCall && (
                <div className="wd-100">
                  <InputLabel id="demo-multiple-chip-label">
                    Resource
                  </InputLabel>
                  <FormControl fullWidth className="mb-10 mt-5">
                    <Select
                      isDisabled={excluded}
                      isClearable={false}
                      isMulti
                      options={getUsers()}
                      value={deliverable.defaultResource}
                      closeMenuOnSelect={false}
                      onChange={(selected) =>
                        handleDataChange({
                          target: { value: selected, name: "resource" },
                        })
                      }
                    />
                  </FormControl>
                </div>
              )}
              {deliverable?.type !== "EXECUTION" && (
                <>
                  {!isWorkflowCall && (
                    <div className="wd-100">
                      <InputLabel id="demo-multiple-chip-label">
                        Approver
                      </InputLabel>
                      <FormControl fullWidth className="mb-10 mt-5">
                        <Select
                          isDisabled={
                            deliverable?.doc?.length !== 0 || excluded
                          }
                          isClearable={false}
                          value={deliverable.defaultApprover}
                          options={getDepartmentUsers()}
                          isMulti
                          closeMenuOnSelect={false}
                          onChange={(selected) => {
                            handleDataChange({
                              target: { value: selected, name: "approver" },
                            });
                          }}
                        />
                      </FormControl>
                    </div>
                  )}
                  {!isWorkflowCall && (
                    <div className="wd-100">
                      <InputLabel id="demo-multiple-chip-label">
                        Reviewer
                      </InputLabel>
                      <FormControl fullWidth className="mb-10 mt-5">
                        <Select
                          isDisabled={
                            deliverable?.doc?.length !== 0 || excluded
                          }
                          isClearable={false}
                          value={deliverable.defaultReviewer}
                          options={getDepartmentUsers()}
                          isMulti
                          closeMenuOnSelect={false}
                          onChange={(selected) => {
                            handleDataChange({
                              target: { value: selected, name: "reviewer" },
                            });
                          }}
                        />
                      </FormControl>
                    </div>
                  )}
                </>
              )}

              {!isWorkflowCall && deliverable?.type === "EXECUTION" && (
                <div className="wd-100">
                  <InputLabel id="demo-multiple-chip-label">
                    Execution
                  </InputLabel>
                  <FormControl fullWidth className="mb-10 mt-5">
                    <Select
                      isClearable
                      isDisabled={excluded}
                      value={{
                        value: deliverable?.executed?.userid,
                        label: getNamebyId(
                          "users",
                          deliverable?.executed?.userid
                        ),
                      }}
                      options={getUsers()}
                      onChange={(selected) =>
                        handleDataChange({
                          target: { value: selected, name: "executed" },
                        })
                      }
                    />
                  </FormControl>
                </div>
              )}
            </Grid>
            {/* ------------- input forms end ------------- */}
            {/* ------------- date pickers start ------------- */}
            <Grid container className="mb-10">
              <Grid item xs={!isWorkflowCall ? 3 : 5} className="mr-20">
                <FormControl fullWidth className="mb-10">
                  {dateRangeSelector("deliverable", "deliverableStart")}
                </FormControl>
              </Grid>
              <Grid item xs={!isWorkflowCall ? 3 : 5} className="mr-20">
                <FormControl fullWidth className="mb-10">
                  {dateRangeSelector("deliverable", "deliverableEnd")}
                </FormControl>
              </Grid>
              {!isWorkflowCall && (
                <Grid item xs={3}>
                  <FormControl fullWidth className="mb-10">
                    <DatePicker
                      disabled={excluded}
                      label="Completion date"
                      minDate={moment(deliverable?.start?.split("T")[0])}
                      value={
                        (deliverable.completion &&
                          moment(deliverable?.completion?.split("T")[0])) ||
                        null
                      }
                      format="MM/dd/yy"
                      onChange={(date) =>
                        handleDataChange({
                          target: {
                            type: "date",
                            value: date,
                            name: "completion",
                          },
                        })
                      }
                      InputProps={{
                        endAdornment: deliverable?.completion && !excluded && (
                          <IconButton
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleDataChange({
                                target: {
                                  type: "date",
                                  value: null,
                                  name: "completion",
                                },
                              });
                            }}
                            className="pd-0"
                          >
                            <Close />
                          </IconButton>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>
              )}
            </Grid>
            {/* ------------- date pickers end ------------- */}
            {/* ------------- hours data start ------------- */}
            {!isWorkflowCall && (
              <Grid container columns={12}>
                <Grid item xs={3} className="mr-20">
                  <TextField
                    fullWidth
                    label="Planned hours"
                    type="number"
                    value={deliverable.plannedHours || 0}
                    name="plannedHours"
                    InputProps={{
                      inputProps: { min: "0", max: "100", step: "1" },
                      disabled: excluded,
                    }}
                    onChange={handleDataChange}
                  />
                </Grid>
                <Grid item xs={3} className="mr-20">
                  <TextField
                    fullWidth
                    label="Worked hours"
                    type="number"
                    value={deliverable.workedHours || 0}
                    name="workedHours"
                    InputProps={{
                      inputProps: { min: "0", max: "100", step: "1" },
                      disabled: excluded,
                    }}
                    onChange={handleDataChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={excluded}
                        checked={deliverable.activity || null}
                        onChange={(event) =>
                          handleDataChange({
                            target: {
                              value: event.target.checked,
                              name: "activity",
                            },
                          })
                        }
                        className="pd-5"
                      />
                    }
                    label={
                      <span style={{ fontSize: "10px", color: "#00000089" }}>
                        Activity
                      </span>
                    }
                    labelPlacement="top"
                  />
                </Grid>
              </Grid>
            )}
            {/* ------------- hours data end ------------- */}
            {/* ------------- status data start ------------- */}
            {!isWorkflowCall && (
              <Grid
                container
                columns={12}
                alignItems="flex-end"
                className="mb-10"
              >
                <Grid item xs={2} className="mr-20">
                  <TextField
                    fullWidth
                    type="number"
                    name="status"
                    label="Status"
                    value={_.round(deliverable.status) || 0}
                    onChange={handleDataChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                      inputProps: { min: 0, max: 100 },
                      disabled: excluded,
                    }}
                  />
                </Grid>
                <Grid item xs={8}>
                  {" "}
                  <BorderLinearProgress
                    variant="determinate"
                    value={_.round(deliverable.status)}
                  ></BorderLinearProgress>
                </Grid>
              </Grid>
            )}
            <Divider className="mt-5 mb-5" />
            {deliverable.type !== "EXECUTION" && (
              <>
                <Grid container columns={12}>
                  <Grid item xs={12}>
                    <Typography variant="body2">Draft</Typography>
                  </Grid>
                  <Grid item xs={!isWorkflowCall ? 2 : 5} className="mr-20">
                    <FormControl fullWidth>
                      {dateRangeSelector("draft", "draftStart")}
                    </FormControl>
                  </Grid>
                  <Grid item xs={!isWorkflowCall ? 2 : 5} className="mr-20">
                    <FormControl fullWidth className="mb-10">
                      {dateRangeSelector("draft", "draftEnd")}
                    </FormControl>
                  </Grid>
                  {!isWorkflowCall && (
                    <Grid item xs={3} className="mr-20">
                      <FormControl fullWidth className="mb-10">
                        <DatePicker
                          disabled={excluded}
                          label="Completion date"
                          value={
                            (deliverable?.draft?.completion &&
                              moment(
                                deliverable?.draft?.completion.split("T")[0]
                              )) ||
                            null
                          }
                          minDate={
                            moment(deliverable?.start?.split("T")[0]) || null
                          }
                          format="MM/dd/yy"
                          onChange={(date) =>
                            handleDataChange({
                              target: {
                                type: "date",
                                value: date,
                                name: "draftCompletion",
                              },
                            })
                          }
                          InputProps={{
                            endAdornment: deliverable?.draft?.completion &&
                              !excluded && (
                                <IconButton
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleDataChange({
                                      target: {
                                        type: "date",
                                        value: null,
                                        name: "draftCompletion",
                                      },
                                    });
                                  }}
                                  className="pd-0"
                                >
                                  <Close />
                                </IconButton>
                              ),
                          }}
                        />
                      </FormControl>
                    </Grid>
                  )}
                  {!isWorkflowCall && (
                    <Grid item xs={2} className="mr-20">
                      <Grid container>
                        <TextField
                          fullWidth
                          type="number"
                          name="status"
                          label="Status"
                          value={_.round(deliverable?.draft?.status) || 0}
                          onChange={(e) =>
                            handleDataChange({
                              target: {
                                value: e.target.value || "0",
                                name: "draftStatus",
                              },
                            })
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            ),
                            inputProps: { min: 0, max: 100 },
                            disabled: excluded,
                          }}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Divider className="mt-5 mb-5" />
                <Grid container columns={12}>
                  <Grid item xs={12}>
                    <Typography variant="body2">IFR</Typography>
                  </Grid>
                  <Grid item xs={!isWorkflowCall ? 2 : 5} className="mr-20">
                    <FormControl fullWidth className="mb-10">
                      {dateRangeSelector("ifr", "ifrStart")}
                    </FormControl>
                  </Grid>
                  <Grid item xs={!isWorkflowCall ? 2 : 5} className="mr-20">
                    <FormControl fullWidth className="mb-10">
                      {dateRangeSelector("ifr", "ifrEnd")}
                    </FormControl>
                  </Grid>
                  {!isWorkflowCall && (
                    <Grid item xs={3} className="mr-20">
                      <FormControl fullWidth className="mb-10">
                        <DatePicker
                          disabled={excluded || deliverable?.doc?.length !== 0}
                          label="Completion date"
                          value={
                            (deliverable?.ifr?.completion &&
                              moment(
                                deliverable?.ifr?.completion.split("T")[0]
                              )) ||
                            null
                          }
                          minDate={
                            moment(deliverable?.start?.split("T")[0]) || null
                          }
                          format="MM/dd/yy"
                          onChange={(date) =>
                            handleDataChange({
                              target: {
                                type: "date",
                                value: date,
                                name: "ifrCompletion",
                              },
                            })
                          }
                          InputProps={{
                            endAdornment: deliverable?.ifr?.completion &&
                              deliverable?.doc?.length === 0 &&
                              !excluded && (
                                <IconButton
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleDataChange({
                                      target: {
                                        type: "date",
                                        value: null,
                                        name: "ifrCompletion",
                                      },
                                    });
                                  }}
                                  className="pd-0"
                                >
                                  <Close />
                                </IconButton>
                              ),
                          }}
                        />
                      </FormControl>
                    </Grid>
                  )}
                  {!isWorkflowCall && (
                    <Grid item xs={1}>
                      <FormControlLabel
                        className="m-0"
                        control={
                          <Checkbox
                            disabled={
                              excluded || deliverable?.doc?.length !== 0
                            }
                            checked={deliverable?.ifr?.issued || null}
                            onChange={(event) =>
                              handleDataChange({
                                target: {
                                  value: event.target.checked,
                                  name: "ifrIssued",
                                },
                              })
                            }
                            className="pd-5"
                          />
                        }
                        label={
                          <span
                            style={{ fontSize: "10px", color: "#00000089" }}
                          >
                            Issued
                          </span>
                        }
                        labelPlacement="top"
                      />
                      {error &&
                        checkValidation("ifrIssued") &&
                        errorMessage("ifrIssued")}
                    </Grid>
                  )}
                </Grid>
                <Divider className="mt-5 mb-5" />
                <Grid container columns={12}>
                  <Grid item xs={12}>
                    <Typography variant="body2">Review</Typography>
                  </Grid>
                  <Grid item xs={!isWorkflowCall ? 2 : 5} className="mr-20">
                    <FormControl fullWidth className="mb-10">
                      {dateRangeSelector("review", "reviewStart")}
                    </FormControl>
                  </Grid>
                  <Grid item xs={!isWorkflowCall ? 2 : 5} className="mr-20">
                    <FormControl fullWidth className="mb-10">
                      {dateRangeSelector("review", "reviewEnd")}
                    </FormControl>
                  </Grid>
                  {!isWorkflowCall && (
                    <Grid item xs={3} className="mr-20">
                      <FormControl fullWidth className="mb-10">
                        <DatePicker
                          disabled={excluded || deliverable?.doc?.length !== 0}
                          label="Completion date"
                          value={
                            (deliverable?.review?.completion &&
                              moment(
                                deliverable?.review?.completion.split("T")[0]
                              )) ||
                            null
                          }
                          minDate={
                            moment(deliverable?.start?.split("T")[0]) || null
                          }
                          format="MM/dd/yy"
                          onChange={(date) =>
                            handleDataChange({
                              target: {
                                type: "date",
                                value: date,
                                name: "reviewCompletion",
                              },
                            })
                          }
                          InputProps={{
                            endAdornment: deliverable?.review?.completion &&
                              deliverable?.doc?.length === 0 &&
                              !excluded && (
                                <IconButton
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleDataChange({
                                      target: {
                                        type: "date",
                                        value: null,
                                        name: "reviewCompletion",
                                      },
                                    });
                                  }}
                                  className="pd-0"
                                >
                                  <Close />
                                </IconButton>
                              ),
                          }}
                        />
                      </FormControl>
                    </Grid>
                  )}
                  {!isWorkflowCall && (
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        type="number"
                        name="status"
                        label="Status"
                        value={_.round(deliverable?.review?.status) || 0}
                        onChange={(e) =>
                          handleDataChange({
                            target: {
                              value: e.target.value || "0",
                              name: "reviewStatus",
                            },
                          })
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                          readOnly: deliverable?.reviewer?.length !== 0,
                          inputProps: { min: 0, max: 100 },
                          disabled: excluded,
                        }}
                      />
                      {error &&
                        checkValidation("reviewStatus") &&
                        errorMessage("reviewStatus")}
                    </Grid>
                  )}
                </Grid>
                <Divider className="mt-5 mb-5" />
                <Grid container columns={12}>
                  <Grid item xs={12}>
                    <Typography variant="body2">IFA</Typography>
                  </Grid>
                  <Grid item xs={!isWorkflowCall ? 2 : 5} className="mr-20">
                    <FormControl fullWidth className="mb-10">
                      {dateRangeSelector("ifa", "ifaStart")}
                    </FormControl>
                  </Grid>
                  <Grid item xs={!isWorkflowCall ? 2 : 5} className="mr-20">
                    <FormControl fullWidth className="mb-10">
                      {dateRangeSelector("ifa", "ifaEnd")}
                    </FormControl>
                  </Grid>
                  {!isWorkflowCall && (
                    <Grid item xs={3} className="mr-20">
                      <FormControl fullWidth className="mb-10">
                        <DatePicker
                          disabled={excluded || deliverable?.doc?.length !== 0}
                          label="Completion date"
                          value={
                            (deliverable?.ifa?.completion &&
                              moment(
                                deliverable?.ifa?.completion.split("T")[0]
                              )) ||
                            null
                          }
                          minDate={
                            moment(deliverable?.start?.split("T")[0]) || null
                          }
                          format="MM/dd/yy"
                          onChange={(date) =>
                            handleDataChange({
                              target: {
                                type: "date",
                                value: date,
                                name: "ifaCompletion",
                              },
                            })
                          }
                          InputProps={{
                            endAdornment: deliverable?.ifa?.completion &&
                              deliverable?.doc?.length === 0 &&
                              !excluded && (
                                <IconButton
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleDataChange({
                                      target: {
                                        type: "date",
                                        value: null,
                                        name: "ifaCompletion",
                                      },
                                    });
                                  }}
                                  className="pd-0"
                                >
                                  <Close />
                                </IconButton>
                              ),
                          }}
                        />
                      </FormControl>
                    </Grid>
                  )}
                  {!isWorkflowCall && (
                    <Grid item xs={1}>
                      <FormControlLabel
                        className="m-0"
                        control={
                          <Checkbox
                            disabled={
                              excluded || deliverable?.doc?.length !== 0
                            }
                            checked={deliverable?.ifa?.issued || null}
                            onChange={(event) =>
                              handleDataChange({
                                target: {
                                  value: event.target.checked,
                                  name: "ifaIssued",
                                },
                              })
                            }
                            className="pd-5"
                          />
                        }
                        label={
                          <span
                            style={{ fontSize: "10px", color: "#00000089" }}
                          >
                            Issued
                          </span>
                        }
                        labelPlacement="top"
                      />
                      {error &&
                        checkValidation("ifaIssued") &&
                        errorMessage("ifaIssued")}
                    </Grid>
                  )}
                </Grid>
                <Divider className="mt-5 mb-5" />
                <Grid container columns={12}>
                  <Grid item xs={12}>
                    <Typography variant="body2">Approval</Typography>
                  </Grid>
                  <Grid item xs={!isWorkflowCall ? 2 : 5} className="mr-20">
                    <FormControl fullWidth className="mb-10">
                      {dateRangeSelector("approval", "approvalStart")}
                    </FormControl>
                  </Grid>
                  <Grid item xs={!isWorkflowCall ? 2 : 5} className="mr-20">
                    <FormControl fullWidth className="mb-10">
                      {dateRangeSelector("approval", "approvalEnd")}
                    </FormControl>
                  </Grid>
                  {!isWorkflowCall && (
                    <Grid item xs={3} className="mr-20">
                      <FormControl fullWidth className="mb-10">
                        <DatePicker
                          disabled={excluded || deliverable?.doc?.length !== 0}
                          label="Completion date"
                          value={
                            (deliverable?.approval?.completion &&
                              moment(
                                deliverable?.approval?.completion.split("T")[0]
                              )) ||
                            null
                          }
                          minDate={
                            moment(deliverable?.start?.split("T")[0]) || null
                          }
                          format="MM/dd/yy"
                          onChange={(date) =>
                            handleDataChange({
                              target: {
                                type: "date",
                                value: date,
                                name: "approvalCompletion",
                              },
                            })
                          }
                          InputProps={{
                            endAdornment: deliverable?.approval?.completion &&
                              deliverable?.doc?.length === 0 &&
                              !excluded && (
                                <IconButton
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleDataChange({
                                      target: {
                                        type: "date",
                                        value: null,
                                        name: "approvalCompletion",
                                      },
                                    });
                                  }}
                                  className="pd-0"
                                >
                                  <Close />
                                </IconButton>
                              ),
                          }}
                        />
                      </FormControl>
                    </Grid>
                  )}
                  {!isWorkflowCall && (
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        type="number"
                        name="status"
                        label="Status"
                        value={_.round(deliverable?.approval?.status) || 0}
                        onChange={(e) =>
                          handleDataChange({
                            target: {
                              value: e.target.value || "0",
                              name: "approvalStatus",
                            },
                          })
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                          readOnly: deliverable?.approver?.length !== 0,
                          inputProps: { min: 0, max: 100 },
                          disabled: excluded,
                        }}
                      />
                      {error &&
                        checkValidation("approvalStatus") &&
                        errorMessage("approvalStatus")}
                    </Grid>
                  )}
                </Grid>
                <Divider className="mt-5 mb-5" />
                {deliverable?.master && (
                  <Grid container columns={12}>
                    <Grid item xs={12}>
                      <Typography variant="body2">Master</Typography>
                    </Grid>
                    <Grid item xs={!isWorkflowCall ? 2 : 5} className="mr-20">
                      <FormControl fullWidth className="mb-10">
                        {dateRangeSelector("masterDate", "masterStart")}
                      </FormControl>
                    </Grid>
                    <Grid item xs={!isWorkflowCall ? 2 : 5} className="mr-20">
                      <FormControl fullWidth className="mb-10">
                        {dateRangeSelector("masterDate", "masterEnd")}
                      </FormControl>
                    </Grid>
                    {!isWorkflowCall && (
                      <Grid item xs={3} className="mr-20">
                        <FormControl fullWidth className="mb-10">
                          <DatePicker
                            label="Completion date"
                            value={
                              (deliverable?.masterDate?.completion &&
                                moment(
                                  deliverable?.masterDate?.completion.split(
                                    "T"
                                  )[0]
                                )) ||
                              null
                            }
                            minDate={
                              moment(deliverable?.start?.split("T")[0]) || null
                            }
                            format="MM/dd/yy"
                            onChange={(date) =>
                              handleDataChange({
                                target: {
                                  type: "date",
                                  value: date,
                                  name: "masterDateCompletion",
                                },
                              })
                            }
                            InputProps={{
                              endAdornment: deliverable?.approval?.completion &&
                                !excluded && (
                                  <IconButton
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      handleDataChange({
                                        target: {
                                          type: "date",
                                          value: null,
                                          name: "masterDateCompletion",
                                        },
                                      });
                                    }}
                                    className="pd-0"
                                  >
                                    <Close />
                                  </IconButton>
                                ),
                            }}
                          />
                        </FormControl>
                      </Grid>
                    )}{" "}
                  </Grid>
                )}
              </>
            )}
            {/* ------------- status data end ------------- */}
            {/* ------------- master file upload start ------------- */}
            {deliverable?.master && deliverable?.status === 100 && (
              <div className="wd-50 mb-10">
                <div className="txt-grey">Master Document</div>
                <input
                  accept=".pdf, .docx, .doc, .xlsx, .xls"
                  className="d-none"
                  type="file"
                  name="file"
                  ref={fileInput}
                  onChange={(e) => handleCapture(e)}
                />
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  component="span"
                  onClick={() => fileInput.current.click()}
                >
                  {fileLoading ? (
                    <CircularProgress />
                  ) : deliverable?.masterFile ? (
                    <div className="d-jsb-cen">
                      <UploadIcon className="primary__icon" />
                      &nbsp;
                      {deliverable?.masterFile?.fileName.substring(
                        deliverable?.masterFile?.fileName.indexOf("-") + 1
                      )}
                    </div>
                  ) : (
                    <div className="d-jsb-cen">
                      <UploadIcon className="primary__icon" />
                      &nbsp; Upload File
                    </div>
                  )}
                </Button>

                <div className="d-jsb-c">
                  {deliverable?.masterFile && (
                    <Typography
                      variant="caption"
                      className="pointer"
                      color="primary"
                      onClick={openFile}
                    >
                      <u>View File</u>
                    </Typography>
                  )}
                  {deliverable?.masterFile && (
                    <Typography
                      style={{ color: "red" }}
                      variant="caption"
                      className="pointer"
                      color="primary"
                      onClick={() => handleRemoveMasterFile()}
                    >
                      <u>Remove File</u>
                    </Typography>
                  )}
                </div>
              </div>
            )}
            {/* ------------- master file upload end ------------- */}
            {/*--------------- view schedule history start*/}
            {!isWorkflowCall && (
              <Button
                className={classes.button}
                variant="contained"
                onClick={() => {
                  setOpenScheduleHistory(true);
                }}
              >
                <RescheduleIcon className="mr-10" />
                View Schedule History
              </Button>
            )}
            {/*--------------- view schedule history end*/}
            {/* ------------- predecessors start ------------- */}
            {deliverable?.predecessors?.length > 0 && (
              <Grid container columns={12} className="mb-10">
                <Grid item xs={12}>
                  <div className="txt-grey">Predecessors</div>
                  {deliverable.predecessors?.map((pre, index) => (
                    <Chip
                      key={index}
                      className="mr-5 border-r-20 mb-10"
                      label={deliverables.find((d) => d._id === pre)?.name}
                    ></Chip>
                  ))}
                </Grid>
              </Grid>
            )}
            {/* ------------- predecessors end ------------- */}
            {/* ------------- document start ------------- */}
            {deliverable?.doc?.length > 0 && (
              <FeatureFlag feature={FEATURE_FLAGS.DocumentManagement}>
                <div className="mb-10">
                  <div className="txt-grey">Document</div>
                  {deliverable.doc?.map((document, index) => (
                    <div key={index}>
                      <Tooltip
                        title={
                          DOC_STATUS.find(
                            (status) => status.value === document.documentStatus
                          ).label
                        }
                      >
                        <Link
                          className="pointer"
                          onClick={() =>
                            history.push(
                              LINKS.EDIT_DOCUMENTS.HREF.replace(
                                ":projectId",
                                projectId
                              ).replace(":id", document.documentId)
                            )
                          }
                        >
                          {document.documentNumber}
                        </Link>
                      </Tooltip>
                    </div>
                  ))}
                </div>
              </FeatureFlag>
            )}
            {/* ------------- document end ------------- */}
            {/* ------------- doeviation start ------------- */}
            {deviationList?.length > 0 && (
              <FeatureFlag feature={FEATURE_FLAGS.DeviationManagement}>
                <div className="mb-10">
                  <div className="txt-grey">Deviation</div>
                  {deviationList?.map((deviation, index) => (
                    <div key={index}>
                      <Tooltip
                        title={
                          DEVIATION_STATUS.find(
                            (status) => status.value === deviation?.status
                          )?.label
                        }
                      >
                        <Link
                          className="pointer"
                          onClick={() =>
                            history.push(
                              LINKS.EDIT_DEVIATIONS.HREF.replace(
                                ":projectId",
                                projectId
                              ).replace(":id", deviation?._id)
                            )
                          }
                        >
                          {deviation?.deviationNumber}
                        </Link>
                      </Tooltip>
                    </div>
                  ))}
                </div>
              </FeatureFlag>
            )}
            {/* ------------- doeviation end ------------- */}
            {/* ------------- auto calculation start ------------- */}
            {!isWorkflowCall && (
              <Grid container columns={12} justify="space-between">
                <Grid item xs={3}>
                  <div className="txt-grey">Lapsed</div>
                  <div className="txt-grey">
                    {_.round(deliverable?.calculated?.lapsed || 0, 2)}
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div className="txt-grey">Differential</div>
                  <div className="txt-grey">
                    {_.round(deliverable?.calculated?.differential || 0, 2)}
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div className="txt-grey">Effort</div>
                  <div className="txt-grey">
                    {_.round(deliverable?.calculated?.effort || 0, 2)}
                  </div>
                </Grid>
              </Grid>
            )}
            {!isWorkflowCall && <Divider className="mt-5 mb-5" />}{" "}
            {!isWorkflowCall && (
              <Grid container columns={12} justify="space-between">
                <Grid item xs={3}>
                  <div className="txt-grey">EV</div>
                  <div className="txt-grey">
                    {_.round(deliverable?.calculated?.EV || 0)}
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div className="txt-grey">PV</div>
                  <div className="txt-grey">
                    {_.round(deliverable?.calculated?.PV || 0)}
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div className="txt-grey">Weight</div>
                  <div className="txt-grey">
                    {_.round(deliverable?.calculated?.weight || 0)}
                  </div>
                </Grid>
              </Grid>
            )}
            {!isWorkflowCall && <Divider className="mt-5 mb-5" />}{" "}
            {!isWorkflowCall && (
              <Grid container columns={12} justify="space-between">
                <Grid item xs={3}>
                  <div className="txt-grey">System EV</div>
                  <div className="txt-grey">
                    {_.round(deliverable?.calculated?.systemEV || 0)}%
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div className="txt-grey">System PV</div>
                  <div className="txt-grey">
                    {_.round(deliverable?.calculated?.systemPV || 0)}%
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div className="txt-grey">System Status</div>
                  <div className="txt-grey">
                    {_.round(deliverable?.calculated?.systemStatus || 0)}%
                  </div>
                </Grid>
              </Grid>
            )}
            {/* ------------- auto calculation end ------------- */}
          </div>
          {!excluded && (
            <>
              <Divider className="mb-5" />
              <div className="d-jsb-r">
                <div className="mr-10">
                  <Button variant="contained" onClick={close}>
                    CANCEL
                  </Button>
                </div>
                <div>
                  <Button
                    disabled={!isWorkflowCall ? checkValidation() : false}
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                  >
                    SAVE
                  </Button>
                </div>
              </div>
            </>
          )}
        </form>
      </CardContent>
      {openFileView && (
        <DocumentViewer
          open={openFileView}
          fileData={deliverable?.masterFile}
          handleClose={() => setOpenFileView(false)}
        />
      )}
      <ScheduleHistoryDialog
        data={deliverable?.scheduleHistory}
        type={deliverable?.type}
        openScheduleHistory={openScheduleHistory}
        handleCloseScheduleHistory={handleCloseScheduleHistory}
      />
    </Card>
  );
};

export default memo(EditDeliverable);
